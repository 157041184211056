import {BrandVariants, createDarkTheme, createLightTheme} from "@fluentui/react-components";

// generate theme from https://aka.ms/themedesigner
const customTheme: BrandVariants = {
    10: "#020305",
    20: "#111722",
    30: "#17263C",
    40: "#1A3251",
    50: "#1D3F67",
    60: "#204C7D",
    70: "#31598B",
    80: "#456695",
    90: "#57739F",
    100: "#6880A9",
    110: "#7A8EB3",
    120: "#8B9CBD",
    130: "#9CABC7",
    140: "#AEBAD1",
    150: "#BFC8DB",
    160: "#D1D7E5"
};



const customThemeDark: BrandVariants = {
    10: "#00000",
    20: "#111722",
    30: "#17263C",
    40: "#1A3251",
    50: "#1D3F67",
    60: "#204C7D",
    70: "#31598B",
    80: "#456695",
    90: "#57739F",
    100: "#6880A9",
    110: "#7A8EB3",
    120: "#8B9CBD",
    130: "#9CABC7",
    140: "#AEBAD1",
    150: "#BFC8DB",
    160: "#D1D7E5"
};


export const lightTheme = createLightTheme(customTheme);
export const darkTheme = createDarkTheme(customThemeDark);

