import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
import { useThemeSwitcher } from "../../utils/themeContext";
import { globalStyles } from "../../utils/globalStyles";

export const AnswerLoading = () => {
    const { isDarkMode } = useThemeSwitcher();

    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    const currentStyles = isDarkMode ? globalStyles.dark : globalStyles.light;

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <AnswerIcon />
                <Stack.Item grow>
                    <p
                        className={styles.answerText}
                        style={{
                            color: currentStyles.textColor
                        }}
                    >
                        Ik ben aan het denken...
                        <span className={styles.loadingdots} />
                    </p>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
