import { msalInstance } from "../index";
import { loginRequest } from "./authConfig";

export async function getHeaders(addContentType: boolean = true) {
    const accounts = msalInstance.getAllAccounts();
    const response = await msalInstance.acquireTokenSilent({
        scopes: [...loginRequest.scopes],
        account: accounts[0]
    });
    const { accessToken } = response;

    const headers = new Headers();
    if (addContentType){
        headers.append("Content-Type", "application/json");
    }
    headers.append("Authorization", `Bearer ${accessToken}`);

    return headers;
}
