import { useThemeSwitcher } from "../../utils/themeContext";
import styles from "./UserChatMessage.module.css";
import { globalStyles } from "../../utils/globalStyles";

interface Props {
    message: string;
}

export const UserChatMessage = ({ message }: Props) => {
    const { isDarkMode} = useThemeSwitcher();

    const currentStyles = isDarkMode ? globalStyles.dark : globalStyles.light;

    return (
        <div className={styles.container}>
            <div
                className={styles.message}
                style={{
                    backgroundColor: currentStyles.backgroundColor,
                    color: currentStyles.textColor
                }}
            >
                {message}
            </div>
        </div>
    );
};
