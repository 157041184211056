export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    SemanticKernelStepwise = "sks"
}

export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export const enum IndexOptions {
    CaesarIndex = "caesarindex",
    ResumeIndex = "resumeindex",
    TestIndex = "testindex"
}

export type AskRequestOverrides = {
    index: IndexOptions;
    retrievalMode?: RetrievalMode;
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    deployment?: string;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type ChatEntry = {
    question: string;
    answer: AskResponse;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    search_index: string;
    error?: string;
};

export type ChatTurn = {
    user: string;
    bot?: any;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type MatchResponse = {
    sourceFile: string;
    requirementMatches: Match[];
};

export type Match = {
    score: number;
    sourcePage: string;
    captions: string[];
    content: string;
    requirement: string;
};

export type SavedChat = {
    id: string;
    lastModified: Date;
    userId: string;
    rating: number;
    comment: string;
    reason: string;
    chatHistory: ChatEntry[];
};

export type ModelDeployment = {
    deploymentName: string;
    baseModel: string;
}