import {Callout, DirectionalHint, Link, Persona, PersonaSize, Stack, Text} from "@fluentui/react";
import styles from "./UserCallOut.module.css";
import "../../index.css";
import {useThemeSwitcher} from "../../utils/themeContext";
import React from "react";
import {CustomToggle} from "../CustomToggle/CustomToggle";
import {customPersonaStyles} from "../../utils/globalStyles";

type CallOutProps = {
    buttonId: string;
    isCalloutVisible: boolean;
    activeAccount?: { username?: string; name?: string } | null;
    setCalloutVisibleFalse: () => void;
    isAdmin?: boolean;
    signOut?: () => void;
    adminPage: () => void;
};

export default function CallOutUser({buttonId, setCalloutVisibleFalse, isCalloutVisible, activeAccount, signOut}: CallOutProps) {
    const {isDarkMode, toggleTheme} = useThemeSwitcher();

    const switchMode = () => {
        toggleTheme();
    };

    return isCalloutVisible ? (
        <Callout
            role="dialog"
            style={{backgroundColor: isDarkMode ? "#292929" : "#ffffff"}}
            gapSpace={10}
            target={`#${buttonId}`}
            isBeakVisible={false}
            onDismiss={setCalloutVisibleFalse}
            directionalHint={DirectionalHint.bottomCenter}
            setInitialFocus
        >
            <div className={`${styles.callout} ${isDarkMode ? styles.calloutDark : styles.calloutLight}`}>
                <Text block variant="small">
                    <Persona text={activeAccount?.name} size={PersonaSize.size48} secondaryText={activeAccount?.username}
                             styles={customPersonaStyles(isDarkMode)}/>
                </Text>
                <div className={styles.themeToggleContainer}>
                    <CustomToggle label="Dark mode" checked={isDarkMode} onClick={switchMode} />
                </div>
                <Stack>
                    <Stack.Item className={styles.linkContainer}>
                        <Link onClick={signOut} aria-label="afmelden">
                            <div className={ isDarkMode? styles.linkTextDark : ""}>
                                Afmelden
                            </div>
                        </Link>
                    </Stack.Item>
                </Stack>
            </div>
        </Callout>
    ) : null;
}