import {IconButton, IPanelProps, Panel, PanelType, Stack} from "@fluentui/react";
import {useThemeSwitcher} from "../../utils/themeContext";

import styles from "./SideHistory.module.css";
import {
    BsChatLeftText,
    BsEnvelopePlus,
} from "react-icons/bs";
import {useLocation, useNavigate} from "react-router-dom";
import {SavedChat} from "../../api";
import {useEffect, useState} from "react";
import {getGlobalPanelStyles} from "../../utils/globalStyles";


type SideHistoryProps = {
    chatHistory: SavedChat[];
    openHistory: (state: boolean) => void;
    deleteChat: () => void;
    open: boolean;
};

type HistoryEntry = {
    id: string;
};

export function SideHistory({chatHistory, openHistory, open, deleteChat}: SideHistoryProps) {
    const {isDarkMode} = useThemeSwitcher();
    const navigate = useNavigate();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const chatId = urlSearchParams.get("chatId");

    const [selectedStackIndex, setSelectedStackIndex] = useState<number | null>(null);

    useEffect(() => {
        setSelectedStackIndex(chatId ? chatHistory.findIndex((historyEntry) => historyEntry.id === chatId) : null);
    }, [chatId, chatHistory]);

    const handleMenuItemClick = async (historyEntry: HistoryEntry, index: any) => {
        setSelectedStackIndex(index);
        const currentPathname = location.pathname;
        navigate(`${currentPathname}?chatId=${historyEntry.id}`);
        openHistory(false);
    };

    function truncateText(text: string, maxLength: number) {
        if (text.length <= maxLength) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        } else {
            return text.charAt(0).toUpperCase() + text.slice(1, maxLength) + "...";
        }
    }

    const renderChatHistory = () => {
        if (chatHistory) {
            return (
                <Stack className={styles.menuList}>
                    {chatHistory.map((historyEntry, index) => (
                        <Stack key={index} horizontal verticalAlign="center"
                               className={`${styles.clickableArea} ${isDarkMode ? styles.menuItemDark : styles.menuItemLight}`}
                               onClick={() => handleMenuItemClick(historyEntry, index)}
                        >
                            <Stack.Item className={styles.stackItemContent}>
                                <BsChatLeftText style={{color: isDarkMode ? "white" : "#292929"}}/>
                            </Stack.Item>

                            <Stack.Item grow
                                        styles={{root: isDarkMode ? styles.textItemStylesDark : styles.textItemStylesLight}}>
                                {truncateText(historyEntry?.chatHistory[0]?.question || "", 24)}
                            </Stack.Item>

                            <Stack.Item styles={{root: styles.actionButtonStyles}}>
                                {selectedStackIndex === index && (
                                    <Stack horizontal styles={{root: styles.buttonGroupStyles}}>
                                        <IconButton
                                            iconProps={{iconName: 'Delete'}}
                                            styles={{root: isDarkMode ? styles.buttonStylesDark : styles.buttonStylesLight}}
                                            onClick={() => deleteChat()}
                                        />
                                    </Stack>
                                )}
                            </Stack.Item>
                        </Stack>
                    ))}
                </Stack>
            );
        }
        return (
            <div className={isDarkMode ? styles.textContainerDark : styles.textContainerLight}>
                No chat history available.
            </div>
        );
    }

    const newChatButton = (props: IPanelProps | undefined) => {
        return (
            <button
                className={`${styles.buttonBase} ${isDarkMode ? styles.newChatButtonStylesDark : styles.buttonStylesLight}`}
                style={{textAlign: "right", flexGrow: 1}}
                onClick={() => {
                    navigate("/");
                    openHistory(false);
                }}>
                <BsEnvelopePlus size={18}/>
                Nieuwe chat
            </button>);
    }

    return (
        <div className={styles.flexContainer}>
            {open && (
                <Panel
                    isOpen={open}
                    type={PanelType.customNear}
                    onDismiss={() => openHistory(false)}
                    styles={getGlobalPanelStyles(isDarkMode)}
                    isBlocking={true}
                    isLightDismiss={true}
                    customWidth={"320px"}
                    onRenderHeader={props => newChatButton(props)}
                >
                    <div className={isDarkMode ? styles.textContainerDark : styles.textContainerLight}>
                        <strong>Chat sessies</strong>
                    </div>
                    {renderChatHistory()}
                </Panel>
            )}
        </div>
    );
}
