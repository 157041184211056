import React, { createContext, useContext, useState } from "react";
import { FluentProvider } from "@fluentui/react-components";
import { darkTheme, lightTheme } from "./theme";

export const ThemeContextMode = createContext<
    | {
          isDarkMode: boolean;
          toggleTheme: () => void;
      }
    | undefined
>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const storedDarkMode = localStorage.getItem("userDarkMode");
    const [isDarkMode, setIsDarkMode] = useState(storedDarkMode === "true");

    const toggleTheme = () => {
        setIsDarkMode(prevMode => !prevMode);
    };

    const currentTheme = isDarkMode ? darkTheme : lightTheme;

    return (
        <FluentProvider className="fluentProvider" theme={currentTheme}>
            <ThemeContextMode.Provider value={{ isDarkMode, toggleTheme }}>{children}</ThemeContextMode.Provider>
        </FluentProvider>
    );
};

export function useThemeSwitcher() {
    const context = useContext(ThemeContextMode);

    if (context === undefined) {
        throw new Error("useThemeSwitcher must be used within a ThemeProvider");
    }

    // Add to session
    localStorage.setItem("userDarkMode", JSON.stringify(context.isDarkMode));

    return context;
}
