import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import styles from "./Layout.module.css";
import Header from "../../components/Headers/MobileHeader";
import { DesktopHeader } from "../../components/Headers/DesktopHeader";

const Layout = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isSmallScreen, setIsSmallScreen] = useState(getCurrentDimension().width <= 768);
    const navigate = useNavigate();

    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(getCurrentDimension().width <= 768);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const options = [
        {
            key: "logout",
            text: "Logout",
            onClick: () => {
                instance.logoutRedirect();
            }
        },
        {
            key: "admin",
            text: "Admin",
            onClick: () => {
                navigate("/admin");
            }
        }
    ];

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    return (
        <div className={styles.layout}>
                <Header options={options} activeAccount={activeAccount} />
            <Outlet />
        </div>
    );
};

export default Layout;
