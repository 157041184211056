import axios, { AxiosInstance, AxiosResponse } from "axios";
import { getHeaders } from "./getHeaders";

const axiosInstance: AxiosInstance = axios.create({});

const authorizedRequest = async <T>(requestConfig: {
    method: string;
    path: string;
    params?: Record<string, string>;
    body?: any;
}): Promise<AxiosResponse<T>> => {
    const { method, path, params = {}, body } = requestConfig;

    axiosInstance.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            return Promise.reject(error);
        }
    );

    const headers = await getHeaders();
    const headersObject: Record<string, string> = {};
    headers.forEach((value, key) => {
        headersObject[key] = value;
    });
    const url = path + (params ? `/${Object.values(params).join("/")}` : "");

    const response = await axiosInstance.request({
        method,
        url,
        data: body,
        headers: headersObject
    });

    return response;
};

export default authorizedRequest;
