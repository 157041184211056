import { useRef, useState, useEffect } from "react";
import {
    Checkbox,
    Panel,
    DefaultButton,
    TextField,
    SpinButton,
    Dropdown,
    IDropdownOption,
    PanelType,
    Modal,
    ChoiceGroup,
    IChoiceGroupOption,
    ITextProps
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

import styles from "./Chat.module.css";
import "../../index.css";

import {
    chatApi,
    RetrievalMode,
    Approaches,
    ChatRequest,
    ChatTurn,
    fetchAllChatHistoryByUserId,
    updateChatHistory,
    createChatHistory,
    deleteChatHistory,
    updateChatHistoryMessage,
    IndexOptions, ChatEntry, getDeployments
} from "../../api";

import {BsHandThumbsDown, BsHandThumbsUp, BsLayoutSidebar} from "react-icons/bs";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useThemeSwitcher } from "../../utils/themeContext";
import { SideHistory } from "../../components/SideHistory/SideHistory";
import { useLocation, useNavigate } from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {checkUserGroupMembership} from "../../utils/authConfig";
import {NewChatButton} from "../../components/NewChatButton/NewChatButton";
import {
    dropdownStyles, getFooterButtonStyles,
    getGlobalPanelStyles,
    getGlobalPanelStylesAccent,
    spinButtonStyles,
    textFieldStyles
} from "../../utils/globalStyles";
import {ChatMenuButton} from "../../components/ChatMenuButton/ChatMenuButton";
import {RiFileSearchLine} from "react-icons/ri";
import {BiSolidNetworkChart} from "react-icons/bi";

type WarningIndicatorProps = {
    condition: boolean;
    icon: JSX.Element;
    label: string;
};

type DropDownItem = {
    key: string;
    text: string;
    selected: boolean;
    data: string;
}

const Chat = () => {
    const { instance } = useMsal();
    const { isDarkMode } = useThemeSwitcher();
    const navigate = useNavigate();

    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [useIndexOverride, setUseIndexOverride] = useState<IndexOptions>(IndexOptions.CaesarIndex);
    const [useDeploymentOverride, setUseDeploymentOverride] = useState<any>();

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatEntry][]>([]);

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [isSmallScreen, setIsSmallScreen] = useState(getCurrentDimension().width <= 768);

    const [chatHistory, setChatHistory] = useState<any[]>([]);
    const [chatId, setChatId] = useState<null | string>(null);

    const location = useLocation();
    const [isModalBadFeedbackOpen, setIsModalBadFeedbackOpen] = useState(false);
    const [isModalGoodFeedbackOpen, setIsModalGoodFeedbackOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
    const [feedbackText, setFeedbackText] = useState<string>("");
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    // open/close history side panel
    const [open, setOpen] = useState<boolean>(false);

    const storedValue = localStorage.getItem("userIndexOverride");
    const userIndexOverride = storedValue ? JSON.parse(storedValue) : IndexOptions.CaesarIndex;

    const [deploymentOptions, setDeploymentOptions] = useState<any>();


    useEffect(() => {
        setUseIndexOverride(userIndexOverride);
    }, [userIndexOverride]);

    useEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
    });

    useEffect(() => {
        const getDeployment = async () => {
            const deployments = await getDeployments();
            let mapped = deployments.map(deployment => ({
                key: deployment.deploymentName,
                text: `${deployment.deploymentName} / ${deployment.baseModel}`,
                selected: useDeploymentOverride == deployment.deploymentName,
                data: deployment.deploymentName
            }));
            setDeploymentOptions(mapped);
        };
        getDeployment();
    }, []);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const history = await fetchAllChatHistoryByUserId();
                setChatHistory(history.data);
            } catch (error) {
                console.error("API call error:", error);
            }
        };
        fetchHistory();
    }, []);

    useEffect(() => {
        clearChat();

        const urlSearchParams = new URLSearchParams(location.search);
        const chatId = urlSearchParams.get("chatId");
        setChatId(chatId);

        if (chatId) {
            clearChat();
            const matchedEntry = chatHistory?.find(entry => entry.id === chatId);

            if (matchedEntry) {
                const historyForAnswers = matchedEntry.chatHistory.map((item: ChatEntry) => [item.question, { question: item.question, answer: item.answer }]);
                setAnswers(historyForAnswers);
            }
        }
    }, [chatHistory, location]);

    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(getCurrentDimension().width <= 768);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    retrievalMode: retrievalMode,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions,
                    index: useIndexOverride,
                    deployment: useDeploymentOverride
                }
            };
            const result = await chatApi(request);
            //update db IF lastQuestionRef.current otherwise CREATE
            const matchedEntry = chatHistory?.find(entry => entry.id === chatId);

            if (matchedEntry) {
                // Update chat history
                await updateChatHistory(matchedEntry, lastQuestionRef, result);

                const updatedChatHistory = [
                    ...matchedEntry.chatHistory,
                    {
                        question: lastQuestionRef.current,
                        answer: result
                    }
                ];

                const updatedEntry = {
                    ...matchedEntry,
                    chatHistory: updatedChatHistory
                };

                let entry: ChatEntry = {
                    question: lastQuestionRef.current,
                    answer: result
                };

                setChatHistory(prevChatHistory =>
                    prevChatHistory === null ? prevChatHistory : prevChatHistory.map(entry => (entry.id === chatId ? updatedEntry : entry))
                );

                setAnswers(answers => [...answers, [lastQuestionRef.current, entry]]);
            } else {
                // Create new chat history
                let entry: ChatEntry = {
                    question: lastQuestionRef.current,
                    answer: result
                };

                let response = await createChatHistory(entry);
                setChatHistory(chatHistory => {
                    return [...chatHistory, response.data]
                });
                const destination = `/?chatId=${response.data.id}`;
                navigate(destination);
                setAnswers(answers => [...answers, [lastQuestionRef.current, entry]]);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    };

    const deleteChat = async () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        // Delete chat history
        try {
            if (chatId !== null) {
                await deleteChatHistory(chatId);

                setChatHistory(chatHistory => {
                    return chatHistory ? chatHistory.filter(chat => chat.id !== chatId) : [];
                });
                setAnswers([]);
                navigate("/");
            } else {
                throw new Error("Chat Id not found");
            }
        } catch (error) {
            return error;
        }
    };

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
        setRetrievalMode(option?.data || RetrievalMode.Hybrid);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onUseIndexOverrideChange = (
        _ev?: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption<IndexOptions> | undefined,
        index?: number | undefined
    ) => {
        setUseIndexOverride(option?.data || IndexOptions.CaesarIndex);
        localStorage.setItem("userIndexOverride", JSON.stringify(option?.data || IndexOptions.CaesarIndex));
    };

    const onUseDeploymentOverrideChange = (
        _ev?: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption<any> | undefined,
        index?: number | undefined
    ) => {
        setUseDeploymentOverride(option?.key || deploymentOptions[0].key);
        setDeploymentOptions(setSelectedByKey(deploymentOptions, option?.key || deploymentOptions[0].key));
    }
    function setSelectedByKey(arr: DropDownItem[], targetKey: string): DropDownItem[] {
        return arr.map(obj => {
            if (obj.key === targetKey) {
                return { ...obj, selected: true };
            }
            return { ...obj, selected: false };
        });
    }

    const getIndexSelectorForRole = () => {
        let indexArray = [{ key: "caesarIndex", text: "Caesar Index", selected: useIndexOverride == IndexOptions.CaesarIndex, data: IndexOptions.CaesarIndex }];

        if (checkUserGroupMembership(instance.getActiveAccount(), "CaesarGovernmentPolicy")) {
            indexArray.push({
                key: "resumeIndex",
                text: "Resume Index",
                selected: useIndexOverride == IndexOptions.ResumeIndex,
                data: IndexOptions.ResumeIndex
            });
        }

        if (checkUserGroupMembership(instance.getActiveAccount(), "AdminPolicy")) {
            indexArray.push({ key: "testIndex", text: "Test Index", selected: useIndexOverride == IndexOptions.TestIndex, data: IndexOptions.TestIndex });
        }

        return indexArray;
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            if (!isSmallScreen) {
                setActiveAnalysisPanelTab(undefined);
            }
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
        setSelectedAnswer(index);
        openPanel();
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            if (!isSmallScreen) {
                setActiveAnalysisPanelTab(undefined);
            }
        } else {
            setActiveAnalysisPanelTab(tab);
        }
        openPanel();
        setSelectedAnswer(index);
    };

    const handleFeedbackClick = (iconType: string) => {
        if (iconType === "thumbsUp") {
            setIsModalGoodFeedbackOpen(true);
            setIsModalBadFeedbackOpen(false);
        } else if (iconType === "thumbsDown") {
            setIsModalBadFeedbackOpen(true);
            setIsModalGoodFeedbackOpen(false);
        }
    };

    const closeModal = () => {
        setIsModalGoodFeedbackOpen(false);
        setIsModalBadFeedbackOpen(false);
        setSelectedOption(undefined);
        setFeedbackText("");
    };

    const radioOptionsBad: IChoiceGroupOption[] = [
        { key: "optionBad1", text: "Onjuiste informatie" },
        { key: "optionBad2", text: "Informatie ontbrak" },
        { key: "optionBad3", text: "Anders" }
    ];

    const radioOptionsGood: IChoiceGroupOption[] = [
        { key: "optionGood1", text: "Juiste informatie" },
        { key: "optionGood2", text: "Heeft mij geholpen" },
        { key: "optionGood3", text: "Anders" }
    ];

    const handleRadioChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setSelectedOption(option?.text);
        validateForm(option?.key, feedbackText);
    };

    const handleTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setFeedbackText(newValue || "");
        validateForm(selectedOption, newValue || "");
    };

    const validateForm = (radioValue: string | undefined, textValue: string) => {
        const isValid = radioValue != undefined && textValue.trim() !== "";
        setIsFormValid(isValid);
    };

    const sendFeedback = async (feedbackType: string) => {
        // update chat history with feedback
        if (chatId) {
            const response = updateChatHistoryMessage(chatId, feedbackType, feedbackText, selectedOption);
        }
    };

    const WarningIndicator: React.FC<WarningIndicatorProps> = ({ icon, label, condition }) => (
        <div className={`${styles.indexOverrideWarning} ${condition ? '' : styles.hidden}`}>
            {icon}
            <span><strong>{label}</strong></span>
        </div>
    );

    return (
        <div className={styles.container}>
                <div className={styles.commandsContainerMain}>
                    <div className={styles.sideContainer}>
                        <div className={styles.sideItem}>
                            <SideHistory chatHistory={chatHistory} openHistory={setOpen} open={open} deleteChat={deleteChat}  />
                        </div>
                    </div>
                    <div className={styles.commandsContainer}>
                        <ChatMenuButton className={styles.commandButton} onClick={() => {setOpen(!open)}}  icon={BsLayoutSidebar}/>
                        <NewChatButton className={styles.commandButton} onClick={() => navigate('/')} />
                        <ClearChatButton className={styles.commandButton} onClick={deleteChat} disabled={answers.length <= 0 || isLoading} />
                        <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                    </div>
                </div>
                <div className={styles.chatRoot}>
                    <div className={isDarkMode ? styles.chatContainerDark : styles.chatContainerLight}>
                        {!lastQuestionRef.current && !chatId ? (
                            <div className={styles.chatEmptyState}>
                                <h1 className={styles.chatEmptyStateTitle}>Chat met je corporate data</h1>
                                <h2 className={styles.chatEmptyStateSubtitle}>Stel je vraag of probeer een voorbeeld?</h2>
                                <ExampleList onExampleClicked={onExampleClicked} />
                            </div>
                        ) : (
                            <div className={styles.chatMessageStream}>
                                {answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={answer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                key={index}
                                                answer={answer[1].answer}
                                                isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                            />
                                        </div>
                                    </div>
                                ))}
                                {isLoading && (
                                    <>
                                        <UserChatMessage message={lastQuestionRef.current} />
                                        <div className={styles.chatMessageGptMinWidth}>
                                            <AnswerLoading />
                                        </div>
                                    </>
                                )}
                                {error ? (
                                    <>
                                        <UserChatMessage message={lastQuestionRef.current} />
                                        <div className={styles.chatMessageGptMinWidth}>
                                            <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                        </div>
                                    </>
                                ) : null}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}

                        <div className={styles.chatInput}>
                            <div className={styles.feedbackButtons}>
                                <div className={styles.warningContainer}>
                                    <WarningIndicator
                                        icon={<BiSolidNetworkChart size={20} />}
                                        label={useDeploymentOverride}
                                        condition={useDeploymentOverride !== undefined}
                                    />
                                    <WarningIndicator
                                        icon={<RiFileSearchLine size={20} />}
                                        label={userIndexOverride}
                                        condition={userIndexOverride !== IndexOptions.CaesarIndex}
                                    />
                                </div>

                                <BsHandThumbsUp
                                    size={20}
                                    onClick={() => handleFeedbackClick("thumbsUp")}
                                    style={answers.length > 0 ? {} : { display: 'none' }}
                                />
                                <BsHandThumbsDown
                                    size={20}
                                    onClick={() => handleFeedbackClick("thumbsDown")}
                                    style={answers.length > 0 ? {} : { display: 'none' }}
                                />
                            </div>
                            {isModalBadFeedbackOpen && (
                                <div className={styles.modalOverlay}>
                                    <Modal isOpen={isModalBadFeedbackOpen} onDismiss={closeModal} isBlocking={false} containerClassName={styles.modalContainer}>
                                        <div className={styles.modalContentHeader}>
                                            <p>Feedback versturen</p>
                                        </div>
                                        <div className={styles.modalContent}>
                                            <div className={styles.modalContent}>
                                                <TextField
                                                    label="Vul hier het probleem in:"
                                                    required={true}
                                                    multiline
                                                    placeholder="Type hier..."
                                                    onChange={handleTextFieldChange}
                                                    styles={{
                                                        root: { width: "100%" },
                                                        fieldGroup: {
                                                            width: "300px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "flex-start"
                                                        }
                                                    }}
                                                />
                                                <ChoiceGroup
                                                    label="Beoordeel chat"
                                                    required={true}
                                                    options={radioOptionsBad}
                                                    selectedKey={selectedOption}
                                                    onChange={handleRadioChange}
                                                    className={styles.modalRadioGroup}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.modalButtonContainer}>
                                            <button
                                                onClick={() => {
                                                    closeModal();
                                                    sendFeedback("thumbsDown");
                                                }}
                                                className={isDarkMode ? styles.buttonStylesDark : styles.buttonStylesLight}
                                                disabled={!isFormValid}
                                            >
                                                <span>Verstuur</span>
                                            </button>
                                        </div>
                                    </Modal>
                                </div>
                            )}
                            {isModalGoodFeedbackOpen && (
                                <div className={styles.modalOverlay}>
                                    <Modal
                                        isOpen={isModalGoodFeedbackOpen}
                                        onDismiss={closeModal}
                                        isBlocking={false}
                                        containerClassName={styles.modalContainer}
                                    >
                                        <div className={styles.modalContentHeader}>
                                            <p>Feedback versturen</p>
                                        </div>
                                        <div className={styles.modalContent}>
                                            <div className={styles.modalContent}>
                                                <TextField
                                                    label="Vul hier in wat goed ging:"
                                                    required={true}
                                                    multiline
                                                    placeholder="Type hier..."
                                                    onChange={handleTextFieldChange}
                                                    styles={{
                                                        root: { width: "100%" },
                                                        fieldGroup: {
                                                            height: "100px",
                                                            width: "300px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "flex-start"
                                                        }
                                                    }}
                                                />
                                                <ChoiceGroup
                                                    label="Beoordeel chat"
                                                    required={true}
                                                    options={radioOptionsGood}
                                                    selectedKey={selectedOption}
                                                    onChange={handleRadioChange}
                                                    className={styles.modalRadioGroup}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.modalButtonContainer}>
                                            <button
                                                onClick={() => {
                                                    closeModal();
                                                    sendFeedback("thumbsUp");
                                                }}
                                                className={isDarkMode ? styles.buttonStylesDark : styles.buttonStylesLight}
                                                disabled={!isFormValid}
                                            >
                                                <span>Verstuur</span>
                                            </button>
                                        </div>
                                    </Modal>
                                </div>
                            )}
                            <QuestionInput clearOnSend placeholder="Stel uw vraag..." disabled={isLoading} onSend={question => makeApiRequest(question)} />
                        </div>
                    </div>

                    {isSmallScreen ? (
                        <Panel
                            isOpen={isOpen}
                            closeButtonAriaLabel="Close"
                            headerText="Analyse"
                            onDismiss={dismissPanel}
                            type={PanelType.smallFluid}
                            styles={getGlobalPanelStylesAccent(isDarkMode)}
                        >
                            {answers.length > 0 && activeAnalysisPanelTab && (
                                <AnalysisPanel
                                    className={styles.chatAnalysisPanel}
                                    activeCitation={activeCitation}
                                    onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                                    citationHeight="1000px"
                                    answer={answers[selectedAnswer][1]}
                                    activeTab={activeAnalysisPanelTab}
                                />
                            )}
                        </Panel>
                    ) : (
                        answers.length > 0 &&
                        activeAnalysisPanelTab && (
                            <AnalysisPanel
                                className={styles.chatAnalysisPanel}
                                activeCitation={activeCitation}
                                onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                                citationHeight="1000px"
                                answer={answers[selectedAnswer][1]}
                                activeTab={activeAnalysisPanelTab}
                            />
                        )
                    )}

                    <Panel
                        headerText="Aangepaste antwoord generatie"
                        isOpen={isConfigPanelOpen}
                        isBlocking={false}
                        onDismiss={() => setIsConfigPanelOpen(false)}
                        closeButtonAriaLabel="Sluiten"
                        onRenderFooterContent={() => <DefaultButton styles={getFooterButtonStyles(isDarkMode)} onClick={() => setIsConfigPanelOpen(false)}>Sluiten</DefaultButton>}
                        isFooterAtBottom={true}
                        styles={getGlobalPanelStyles(isDarkMode)}
                    >
                        <div className={isDarkMode ? styles.checkBoxWrapDark : styles.checkBoxWrapLight} style={{ marginTop: "30px" }}>
                            <span>Overschrijf prompt template</span>
                            <TextField
                                className={`${styles.chatSettingsSeparator}`}
                                styles={textFieldStyles(isDarkMode)}
                                defaultValue={promptTemplate}
                                multiline
                                autoAdjustHeight
                                onChange={onPromptTemplateChange}
                            />

                            <span>Haal zoveel documenten op uit search:</span>
                            <SpinButton
                                className={styles.chatSettingsSeparator}
                                styles={spinButtonStyles(isDarkMode)}
                                min={1}
                                max={50}
                                defaultValue={retrieveCount.toString()}
                                onChange={onRetrieveCountChange}
                            />
                            <span>Categorieën uitsluiten:</span>
                            <TextField className={styles.chatSettingsSeparator} styles={textFieldStyles(isDarkMode)} onChange={onExcludeCategoryChanged} />

                            <div className={styles.checkBoxWrap}>
                                <div className={styles.checkBoxGroup}>
                                    <Checkbox className={styles.chatSettingsSeparator} checked={useSemanticRanker} onChange={onUseSemanticRankerChange} />
                                    <span>Gebruik semantische rangschikking voor het ophalen van documenten</span>
                                </div>
                                <div className={styles.checkBoxGroup}>
                                    <Checkbox
                                        className={styles.chatSettingsSeparator}
                                        checked={useSemanticCaptions}
                                        onChange={onUseSemanticCaptionsChange}
                                        disabled={!useSemanticRanker}
                                    />
                                    <span>Gebruik query-contextuele samenvattingen in plaats van hele documenten</span>
                                </div>
                                <div className={styles.checkBoxGroup}>
                                    <Checkbox
                                        className={styles.chatSettingsSeparator}
                                        checked={useSuggestFollowupQuestions}
                                        onChange={onUseSuggestFollowupQuestionsChange}
                                    />
                                    <span>Stel vervolgvragen voor</span>
                                </div>
                            </div>
                            <label>Ophaalmodus</label>
                            <Dropdown
                                className={styles.chatSettingsSeparator}
                                styles={dropdownStyles(isDarkMode)}
                                options={[
                                    {
                                        key: "hybrid",
                                        text: "Vectors + Text (Hybrid)",
                                        selected: retrievalMode == RetrievalMode.Hybrid,
                                        data: RetrievalMode.Hybrid
                                    },
                                    { key: "vectors", text: "Vectors", selected: retrievalMode == RetrievalMode.Vectors, data: RetrievalMode.Vectors },
                                    { key: "text", text: "Text", selected: retrievalMode == RetrievalMode.Text, data: RetrievalMode.Text }
                                ]}
                                required
                                onChange={onRetrievalModeChange}
                            />
                            <br />
                            <span>Override Index</span>
                            <Dropdown className={styles.chatSettingsSeparator} styles={dropdownStyles(isDarkMode)} options={getIndexSelectorForRole()} onChange={onUseIndexOverrideChange} />

                            <br />
                            <label>Override: Deployment / Model</label>
                            <Dropdown className={styles.chatSettingsSeparator} styles={dropdownStyles(isDarkMode)} options={deploymentOptions} onChange={onUseDeploymentOverrideChange} />
                        </div>  
                    </Panel>
                </div>
            </div>
    );
};

export default Chat;
