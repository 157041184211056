import { Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";

import { SupportingContent } from "../SupportingContent";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import styles from "./AnalysisPanel.module.css";
import {getPivotStyles} from "../../utils/globalStyles";
import {useThemeSwitcher} from "../../utils/themeContext";


interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: any;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged }: Props) => {

    const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.answer.data_points.length;
    const isDisabledCitationTab: boolean = !activeCitation;
    const { isDarkMode } = useThemeSwitcher();


    const sanitizedThoughts = DOMPurify.sanitize(answer.answer.thoughts!);

    // Checks if the answer has supporting content.
    // this is a temporary solution, because the API returns different information for chat and ask
    let supportingContent: string[] = answer.data_points ?? answer.answer.data_points;

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            styles={getPivotStyles(isDarkMode)}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Denkproces"
                headerButtonProps={!isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Ondersteunende bronnen"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent supportingContent={supportingContent} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citatie"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                <iframe title="Citation" src={activeCitation} width="100%" height={citationHeight} />
            </PivotItem>
        </Pivot>
    );
};
