import {FormNew24Regular} from "@fluentui/react-icons";

import styles from "./NewChatButton.module.css";

type NewChatButtonProps = {
    className?: string;
    onClick: () => void;
}

export const NewChatButton = ({onClick, className }: NewChatButtonProps) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`} onClick={onClick}>
            <FormNew24Regular />
            <p>{"Nieuwe chat"}</p>
        </div>
    );
};
