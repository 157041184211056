import styles from "../../pages/layout/Layout.module.css";
import logo from "../../assets/logo.svg";
import React, {useCallback, useEffect, useState} from "react";
import {Icon, INavLink, INavLinkGroup, INavStyles, Nav} from "@fluentui/react";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";
import {useLocation, useNavigate} from "react-router-dom";
import UserMenu from "../UserMenu/UserMenu";
import {AccountInfo} from "@azure/msal-browser";
import {getGlobalPanelStyles, getNavStyles} from "../../utils/globalStyles";
import {useThemeSwitcher} from "../../utils/themeContext";

type MobileHeaderProps = {
    options: { key: string; text: string; onClick: () => void }[];
    activeAccount: AccountInfo | null;
};

export default function Header(props: MobileHeaderProps) {
    const [open, setOpen] = useState<boolean>(false);
    const {isDarkMode} = useThemeSwitcher();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Close the panel when navigating to a different page
        setOpen(false);
    }, [location]);

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: "Chat",
                    url: "/",
                    icon: "Chat"
                },
                {
                    name: "Stel een vraag",
                    url: "/qa",
                    icon: "Questionnaire"
                },
                {
                    name: "Aanvraag matcher",
                    url: "/inquiry",
                    icon: "Link"
                }
            ]
        }
    ];

    // Add admin navigation if user is admin
    let isAdmin = props.activeAccount?.idTokenClaims?.roles?.includes("Admin");
    if (isAdmin) {
        navLinkGroups.push({
            name: "Admin",
            expandAriaLabel: "Expand Admin section",
            links: [
                {
                    name: "Documents",
                    url: "/admin/documents",
                    key: "documents",
                    icon: "Document"
                },
                {
                    name: "Gebruikers Feedback",
                    url: "/admin/userfeedback",
                    key: "feedback",
                    icon: "Questionnaire"
                }
            ]
        });
    }

    function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        ev?.preventDefault();
        navigate(item?.url || "/");
    }

    const onRenderFooterContent = useCallback(
        () => (
            <UserMenu options={props.options} activeAccount={props.activeAccount}/>
        ),
        []
    );

    return (
        <header className={styles.header} role="banner">
            <div className={styles.headerContainer}>
                <img className={styles.logoCaesar} src={logo} alt="company logo"/>
                <div>
                    <div className={styles.centerIcon}>
                        <Icon
                            iconName="GlobalNavButton"
                            onClick={() => {
                                setOpen(true);
                            }}
                            className={styles.navMenuButton}
                        />
                    </div>
                    <Panel
                        isLightDismiss
                        isOpen={open}
                        onDismiss={() => setOpen(false)}
                        closeButtonAriaLabel="Close"
                        headerText="Navigation"
                        onRenderFooterContent={onRenderFooterContent}
                        isFooterAtBottom={true}
                        type={PanelType.smallFixedFar}
                        styles={getGlobalPanelStyles(isDarkMode)}
                    >
                        <Nav onLinkClick={_onLinkClick} groups={navLinkGroups} styles={getNavStyles(isDarkMode)}/>
                    </Panel>
                </div>
            </div>
        </header>
    );
}
