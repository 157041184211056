import {AccountInfo, Configuration} from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: import.meta.env.VITE_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${import.meta.env.VITE_TENANT_ID}`,
        redirectUri: window.location.origin,
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const loginRequest = {
    scopes: [`api://${import.meta.env.VITE_CLIENT_ID}/.default`]
};

type AuthorizationPolicyDetails = {
    AllowedGroups: string[];
}

// Create a mapping type for AuthorizationPolicies
type AuthorizationPoliciesType = {
    [key: string]: AuthorizationPolicyDetails;
};

export const AuthorizationPolicies: AuthorizationPoliciesType  = {
    AdminPolicy: {
        AllowedGroups: ["83bcb26a-737c-458f-8f8d-0b18f3614183"]
    },
    CaesarGovernmentPolicy: {
        AllowedGroups: ["502a43ab-a888-47c2-bfcd-5995fe36f7f7", "83bcb26a-737c-458f-8f8d-0b18f3614183"]
    }
};

export function checkUserGroupMembership(account: AccountInfo | null, policyName: string) {
    if (account === null) return false;
    if (!account.idTokenClaims) return false;

    const userGroups = account.idTokenClaims.groups as string[];
    const policy = AuthorizationPolicies[policyName];

    // Make sure policy exists
    if (!policy) return false;

    const allowedGroups = policy.AllowedGroups;

    // Check if any of the user's groups match the allowed groups for the policy
    return userGroups.some(userGroupId => allowedGroups.includes(userGroupId));
}