import {Persona, PersonaSize} from "@fluentui/react";
import {useBoolean, useId} from "@fluentui/react-hooks";
import styles from "./UserMenu.module.css";
import CallOutUser from "../UserCallOut/UserCallOut";
import {useThemeSwitcher} from "../../utils/themeContext";
import {useMsal} from "@azure/msal-react";
import {checkUserGroupMembership} from "../../utils/authConfig";

type UserMenuProps = {
    options: { key: string; text: string; onClick: () => void }[];
    activeAccount?: { username?: string; name?: string } | null;
    mobile?: boolean;
};

const UserMenu = ({options, activeAccount}: UserMenuProps) => {
    const {instance} = useMsal();
    const buttonId = useId("callout-button");
    const [isCalloutVisible, {toggle: toggleIsCalloutVisible}] = useBoolean(false);
    const {isDarkMode} = useThemeSwitcher();

    let isAdmin = checkUserGroupMembership(instance.getActiveAccount(), "AdminPolicy")

    function setCalloutVisibleFalse() {
        toggleIsCalloutVisible();
    }

    const customPersonaStyles = {
        primaryText: {
            color: isDarkMode ? "white" : "black",
            selectors: {
                ":hover": {
                    color: isDarkMode ? "white" : "black" // Ensure color doesn't change on hover
                }
            }
        }
    };

    return (
        <>
            <div className={styles.textWithBackground}>
                <Persona
                    text={activeAccount?.name}
                    size={PersonaSize.size32}
                    className={styles.personaCursor}
                    id={buttonId}
                    onClick={toggleIsCalloutVisible}
                    styles={customPersonaStyles}
                />
                <CallOutUser
                    buttonId={buttonId}
                    isCalloutVisible={isCalloutVisible}
                    setCalloutVisibleFalse={setCalloutVisibleFalse}
                    activeAccount={activeAccount}
                    signOut={options[0].onClick}
                    adminPage={options[1].onClick}
                    isAdmin={isAdmin}
                />
            </div>
        </>
    );
};

export default UserMenu;
