import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { msalConfig } from "./utils/authConfig";
import { ThemeProvider } from "./utils/themeContext";

initializeIcons();

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance
    .handleRedirectPromise()
    .then(result => {
        if (result) {
            const account = result.account;
            msalInstance.setActiveAccount(account);
        }
    })
    .catch(error => {
        console.error(error);
    });

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: "admin",
                lazy: () => import("./pages/admin/admin"),
                children: [
                    {
                        path: "documents",
                        lazy: () => import("./components/AdminComponents/AdminFileUpload/AdminFileUpload")
                    },
                    {
                        path: "userfeedback",
                        lazy: () => import("./pages/userfeedback/UserFeedbackPage")
                    },
                ]
            },
            {
                path: "qa",
                lazy: () => import("./pages/oneshot/OneShot")
            },
            {
                path: "inquiry",
                lazy: () => import("./pages/inquiry/Inquiry")
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <ThemeProvider>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <RouterProvider router={router} />
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </ThemeProvider>
    </React.StrictMode>
);
