import {IButtonStyles, IDropdownStyles, IPivotStyles, ISpinButtonStyles, ITextFieldStyles} from "@fluentui/react";

export const getGlobalStyles = () => ({
    dark: {
        backgroundColor: "var(--darkBackGroundColor) !important",
        textColor: "white !important",
        accentColor: "var(--darkBackGroundColorAccent) !important"
    },
    light: {
        backgroundColor: "var(--lightBackGroundColor) !important",
        textColor: "black !important",
        accentColor: "var(--lightBackGroundColorAccent) !important"
    }
});

export const globalStyles = getGlobalStyles();

export const getGlobalPanelStyles = (isDarkMode: boolean) => {
    const globalStyles = getGlobalStyles();

    return {
        main: {
            backgroundColor: globalStyles[isDarkMode ? "dark" : "light"].backgroundColor
        },
        commands: {
            backgroundColor: globalStyles[isDarkMode ? "dark" : "light"].backgroundColor
        },
        header: {
            backgroundColor: globalStyles[isDarkMode ? "dark" : "light"].backgroundColor
        },
        headerText: {
            color: globalStyles[isDarkMode ? "dark" : "light"].textColor
        },
        closeButton: {
            color: globalStyles[isDarkMode ? "light" : "dark"].textColor,
            selectors: {
                ":hover": {
                    color: globalStyles[isDarkMode ? "dark" : "light"].textColor,
                    backgroundColor: globalStyles[isDarkMode ? "dark" : "light"].accentColor
                },
                '.ms-Icon': {
                    color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
                },
            }
        },
        textField: {
            field: {
                backgroundColor: "black !important",
            },
            textarea: {
                backgroundColor: "black !important",
            }
        },
    };
};

export const getGlobalPanelStylesAccent = (isDarkMode: boolean) => {
    const globalStyles = getGlobalStyles();

    return {
        main: {
            backgroundColor: globalStyles[isDarkMode ? "dark" : "light"].accentColor
        },
        root: {
            width: "400px"
        }
    };
};


export const customPersonaStyles = (isDarkMode: boolean) => {
    return {
        primaryText: {
            color: isDarkMode ? "white" : "black",
            selectors: {
                ":hover": {
                    color: isDarkMode ? "white" : "black"
                }
            }
        },
        secondaryText: {
            color: isDarkMode ? "grey" : "black",
            selectors: {
                ":hover": {
                    color: isDarkMode ? "grey" : "black"
                }
            }
        }
    }
};


export const getNavStyles = (isDarkMode: boolean) => {
    const globalStyles = getGlobalStyles();

    return {
        root: {
            backgroundColor: globalStyles[isDarkMode ? 'dark' : 'light'].backgroundColor,
        },

        groupContent: {
            marginTop: '20px',
        },

        groupHeader: {
            backgroundColor: globalStyles[isDarkMode ? 'dark' : 'light'].backgroundColor,
            color: globalStyles[isDarkMode ? 'dark' : 'light'].textColor,
        },

        chevronButton: {
            backgroundColor: globalStyles[isDarkMode ? 'dark' : 'light'].backgroundColor,
            color: globalStyles[isDarkMode ? 'dark' : 'light'].textColor,
        },

        link: {
            color: globalStyles[isDarkMode ? 'dark' : 'light'].textColor,
            borderRadius: '5px',
            selectors: {
                '.ms-Icon': {
                    color: globalStyles[!isDarkMode ? 'dark' : 'light'].backgroundColor,
                },
                ':hover .ms-Icon': {
                    color: globalStyles[!isDarkMode ? 'dark' : 'light'].backgroundColor,
                },
                '&:hover .ms-Button': {
                    backgroundColor: isDarkMode ? '#444 !important' : '#f0f0f0 !important',
                },
                '&.is-selected .ms-Button': {
                    backgroundColor: isDarkMode ? '#555 !important' : '#e0e0e0 !important',
                },
            },
        },

        linkText: {
            color: globalStyles[isDarkMode ? 'dark' : 'light'].textColor,
            selectors: {
                '&:hover': {
                    color: isDarkMode ? '#f0f0f0' : '#555',
                }
            }
        },

        compositeLink: {
            color: globalStyles[isDarkMode ? 'dark' : 'light'].textColor,
            selectors: {
                '&:hover .ms-Button': {
                    backgroundColor: isDarkMode ? '#444 !important' : '#f0f0f0 !important',
                },
                '&.is-selected .ms-Button': {
                    backgroundColor: isDarkMode ? '#555 !important' : '#e0e0e0 !important',
                },
            },
        },

        linkIcon: {
            color: globalStyles[isDarkMode ? 'dark' : 'light'].textColor,
            selectors: {
                '&:hover': {
                    color: isDarkMode ? '#f0f0f0' : '#555',
                },
                '&.is-selected': {
                    color: isDarkMode ? '#f0f0f0' : '#555',
                },
            },
        }
    };
};


export const textFieldStyles = (isDarkMode: boolean): Partial<ITextFieldStyles> => {
    return {
        field: {
            backgroundColor: globalStyles[isDarkMode ? 'dark' : 'light'].backgroundColor,
            color: globalStyles[isDarkMode ? 'dark' : 'light'].textColor,
            '&:hover': {
                backgroundColor: globalStyles[isDarkMode ? 'dark' : 'light'].accentColor,
                borderColor: globalStyles[isDarkMode ? 'dark' : 'light'].accentColor,
            }
        },

    }
}

export const spinButtonStyles = (isDarkMode: boolean): Partial<ISpinButtonStyles> => {
    const globalStyles = getGlobalStyles();

    const themeStyles = isDarkMode ? globalStyles.dark : globalStyles.light;

    const commonStyles = {
        backgroundColor: themeStyles.backgroundColor,
        color: themeStyles.textColor,
    };

    return {
        root: commonStyles,
        input: {
            ...commonStyles,
            '&:hover': {
                backgroundColor: themeStyles.accentColor,
                borderColor: themeStyles.accentColor,
            }
        },
        icon: {
            color: themeStyles.textColor,
            '&:hover': {
                color: themeStyles.textColor,
                backgroundColor: themeStyles.backgroundColor,
            }
        },
        spinButtonWrapper: {
            ...commonStyles,
            '.ms-UpButton, .ms-DownButton': {
                backgroundColor: globalStyles[isDarkMode ? 'dark' : 'light'].backgroundColor,
                color: globalStyles[isDarkMode ? 'dark' : 'light'].textColor,
            },
            '.ms-UpButton:hover, .ms-DownButton:hover': {
                backgroundColor: globalStyles[isDarkMode ? 'dark' : 'light'].accentColor,
            },
            '.ms-UpButton:active, .ms-DownButton:active': {
                backgroundColor: globalStyles[isDarkMode ? 'dark' : 'light'].accentColor,
            }
        }
    }
}


export const dropdownStyles = (isDarkMode: boolean): Partial<IDropdownStyles> => {
    const globalStyles = getGlobalStyles();

    return {
        dropdown: {
            backgroundColor: isDarkMode ? globalStyles.dark.backgroundColor : globalStyles.light.backgroundColor,
            color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
        },
        title: {
            backgroundColor: isDarkMode ? globalStyles.dark.backgroundColor : globalStyles.light.backgroundColor,
            color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
            ':hover': {
                backgroundColor: isDarkMode ? globalStyles.dark.accentColor : globalStyles.light.accentColor,
            },
            ':focus': {
                borderColor: isDarkMode ? globalStyles.dark.accentColor : globalStyles.light.accentColor,
            }
        },
        dropdownItem: {
            color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
            backgroundColor: isDarkMode ? globalStyles.dark.backgroundColor : globalStyles.light.backgroundColor,
            ':hover': {
                backgroundColor: isDarkMode ? globalStyles.dark.accentColor : globalStyles.light.accentColor,
                color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
            },
            ':focus': {
                backgroundColor: isDarkMode ? globalStyles.dark.accentColor : globalStyles.light.accentColor,
            }
        },

        caretDownWrapper: {
            color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
            ':hover': {
                color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
            }
        },
        caretDown: {
            color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
        },

    }
};

export const getFooterButtonStyles = (isDarkMode: boolean): Partial<IButtonStyles> => {
    const globalStyles = getGlobalStyles();

    return {
        root: {
            backgroundColor: isDarkMode ? globalStyles.dark.backgroundColor : globalStyles.light.backgroundColor,
            color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
            ':hover': {
                backgroundColor: isDarkMode ? globalStyles.dark.backgroundColor : globalStyles.light.backgroundColor,
                color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
            },
            ':focus': {
                borderColor: isDarkMode ? globalStyles.dark.backgroundColor : globalStyles.light.backgroundColor,
                color: isDarkMode ? globalStyles.dark.textColor : globalStyles.light.textColor,
            }
        }

    };
};

export const getPivotStyles = (isDarkMode: boolean): Partial<IPivotStyles> => {
    const themeStyles = isDarkMode ? globalStyles.dark : globalStyles.light;

    return {
        root: {
            backgroundColor: themeStyles.backgroundColor,
            color: themeStyles.textColor,
        },
        link: {
            ':hover': {
                backgroundColor: themeStyles.accentColor,
            },
            ':active': {
                backgroundColor: themeStyles.accentColor,
            }

        },
        linkIsSelected: {
            ':after': {
                borderColor: themeStyles.accentColor,
            }
        },
        linkContent: {
            color: themeStyles.textColor,
        },
        text: {
            color: themeStyles.textColor,
        },
    };
};