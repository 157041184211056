import {IconType} from "react-icons";

import styles from "./ChatMenuButton.module.css";

interface Props {
    className?: string;
    icon: IconType ;
    onClick: () => void;
    disabled?: boolean;
}

export const ChatMenuButton = ({ className, disabled, onClick, icon }: Props) => {
    const Icon: IconType = icon;

    return (
        <div className={`${styles.container} ${className ?? ""} ${disabled && styles.disabled}`} onClick={onClick}>
            <Icon size={22} />
            <p>{"Open History"}</p>
        </div>
    );
};
