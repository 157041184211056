import {AskRequest, AskResponse, ChatRequest, MatchResponse, ModelDeployment, SavedChat} from "./models";
import authorizedRequest from "../utils/authorizedRequest";
import {getHeaders} from "../utils/getHeaders";

export async function askApi(options: AskRequest): Promise<AskResponse> {

    const response = await fetch("/ask", {
        method: "POST",
        headers: await getHeaders(),
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                retrieval_mode: options.overrides?.retrievalMode,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                index: options.overrides?.index,
                deployment: options.overrides?.deployment,
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {

    const response = await fetch(`/chat`, {
        method: "POST",
        headers: await getHeaders(),
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            overrides: {
                retrieval_mode: options.overrides?.retrievalMode,
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                index: options.overrides?.index,
                deployment: options.overrides?.deployment,
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function uploadInquiry(formData: FormData): Promise<string[]> {
    const response = await fetch('/inquiry/requirements', {
        method: 'POST',
        body: formData,
        headers: await getHeaders(false),
    });

    const requirements: string[] = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }
    return requirements;
}

export async function searchCandidatesForInquiry(requirements: string[]): Promise<MatchResponse[]> {
    const response = await fetch('/inquiry/candidates', {
        method: 'POST',
        body: JSON.stringify(requirements),
        headers: await getHeaders(),
    });

    const parsedResponse: MatchResponse[] = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }
    return parsedResponse;
}

export async function getCitationFilePath(container: string, citation: string): Promise<string> {
    // if a citation has a http in it, it is an url and we can just return it because it is already a link
    if (citation.match(/http/g)) {
        return citation;
    }

    let response = await fetch(`/content/${container}/${citation}`, {
        method: 'GET',
        headers: await getHeaders(),
    });

    const regex = /#page=(\d+)/;
    const match = citation.match(regex);
    const documentPage = match && match[1] ? match[1] : 1;

    let blob = await response.blob();
    return URL.createObjectURL(blob) + `#page=${documentPage}`
}

export async function getIndexes(): Promise<any> {
    const response = await fetch("/index", {
        method: "GET",
        headers: await getHeaders(),
    });
    return response.json()
}


export async function getDocuments(blobContainer: string): Promise<any> {
    const response = await fetch(`/index/${blobContainer}/blobs`, {
        method: "GET",
        headers: await getHeaders(),
    });
    return response.json()
}


export async function uploadPdf(formData: FormData) {
    const response = await fetch("/content/pdf", {
        method: "POST",
        headers: await getHeaders(false),
        body: formData
    });
    return response
}

export async function deleteFromIndex(data: any, blobName: string) {
    const response = await fetch(`/index/${blobName}/delete/file`, {
        method: "DELETE",
        headers: await getHeaders(true),
        body: JSON.stringify(data)
    });
    return response
}

export async function getDeployments(): Promise<ModelDeployment[]> {
    const response = await fetch(`/modeldeployment/deployments`, {
        method: "GET",
        headers: await getHeaders(),
    });
    return response.json()
}

export function isValidHttpUrl(string: string): boolean {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

// CHAT HISTORY ROUTES
export async function fetchAllChatHistoryByUserId() {
    const response = await authorizedRequest<SavedChat[]>({
        method: "GET",
        path: "/history"
    });

    return response;
}

export async function updateChatHistory(matchedEntry: any, lastQuestionRef: any, result: AskResponse) {
    const response = await authorizedRequest({
        method: "PUT",
        path: "/history/message",
        params: {
            chatId: matchedEntry.id
        },
        body: {
            question: lastQuestionRef.current,
            answer: result
        }
    });
    return response;
}

export async function createChatHistory(newChatMessage: any) {
    const response = await authorizedRequest<SavedChat>({
        method: "POST",
        path: "/history",
        body: {
            lastmodified: new Date(),
            chatHistory: [newChatMessage]
        }
    });

    return response;
}

export async function updateChatHistoryMessage(chatId: string, feedbackType: string, feedbackText: string, selectedOption: string | undefined) {
    const response = await authorizedRequest({
        method: "PUT",
        path: "/history",
        params: {
            chatId: chatId
        },
        body: {
            rating: feedbackType == "thumbsUp" ? 1 : 0,
            comment: feedbackText,
            reason: selectedOption
        }
    });

    return response;
}

export async function deleteChatHistory(chatId: string) {
    const response = await authorizedRequest({
        method: "DELETE",
        path: "/history",
        params: {
            chatId: chatId
        }
    });

    return response;
}