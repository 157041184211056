import {Toggle} from "@fluentui/react";
import React from "react";

type CustomToggleProps = {
    label: string;
    onClick: () => void;
    checked: boolean;
}

export function CustomToggle(props: CustomToggleProps) {
    return (
        <div style={{ display: 'flex'}}>
            <span style={{ marginRight: 8 }}>{props.label}</span>
            <Toggle {...props} label="" />
        </div>
    );
}