import { useEffect, useMemo, useState } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import {AskResponse, getCitationFilePath, IndexOptions, isValidHttpUrl} from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useThemeSwitcher } from "../../utils/themeContext";
import { globalStyles } from "../../utils/globalStyles";

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked), [answer]);

    const [citationPaths, setCitationPaths] = useState<string[]>([]);

    useEffect(() => {
        const fetchCitationPaths = async () => {
            const paths: string[] = [];
            for (const citation of parsedAnswer.citations) {

                let index = answer.search_index
                if (index === undefined || index === null) {
                    index = IndexOptions.CaesarIndex;
                }

                const path = await getCitationFilePath(index, citation);
                paths.push(path);
            }
            setCitationPaths(paths);
        };
        fetchCitationPaths();
    }, [parsedAnswer.citations]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    const { isDarkMode } = useThemeSwitcher();

    const currentStyles = isDarkMode ? globalStyles.dark : globalStyles.light;

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div className={styles.iconContainer}>
                        <IconButton
                            className={isDarkMode ? styles.iconDark : styles.iconLight}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Laat denkproces zien"
                            ariaLabel="Laat denkproces zien"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            className={isDarkMode ? styles.iconDark : styles.iconLight}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Laat ondersteunende bronnen zien"
                            ariaLabel="Laat ondersteunende bronnen zien"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div
                    className={styles.answerText}
                    style={{
                        color: currentStyles.textColor
                    }}
                    dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}
                ></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span
                            className={styles.citationLearnMore}
                            style={{
                                color: currentStyles.textColor
                            }}
                        >
                            Citaties:
                        </span>
                        {parsedAnswer.citations.map((x, i) => {
                            if (isValidHttpUrl(x)) {
                                return <a key={i} href={x} className={styles.citation} target="_blank">{`${++i}. ${x}`}</a>;
                            } else {
                                const path = citationPaths[i];
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            }
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};